<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah <b>Laporan Tindakan Medis</b> yang ada di Klinik IONA
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/medical-actions/add')"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div class="row justify-content-start">
              <div class="col-md-6">
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('year')"
                  v-bind:class="{
                    'btn-info': btnActive.year,
                    'btn-outline-info': btnActive.year == false,
                  }"
                >
                  Tahun Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button>
              </div>

              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.patient_name"
                    placeholder="Cari Nama Pasien"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <treeselect
                    v-model="filter.action_type"
                    :multiple="false"
                    :options="action_types"
                    @input="filterByActionType"
                  />
                </b-input-group>
              </div>
            </div>

            <div class="row justify-content-between align-items-center mt-3">
              <div class="col-md-8" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="pagination" variant="success"
                        >Cari</b-button
                      >
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button
                            squared
                            @click="pagination"
                            variant="success"
                            >Cari</b-button
                          >
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>

              <div class="col-8" v-if="onShowPeriod == false"></div>

              <div class="col-4 d-flex justify-content-end">
                <button class="btn btn-primary mr-2" @click="btnExportOnClick">
                  Ekspor <i class="fas fa-print px-0 ml-1"></i>
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2">
                <span
                  style="width: 150px; color: #245590 !important"
                  class="font-weight-bold"
                  >{{ recordData }} Baris Data</span
                >
              </div>
            </div>

            <b-table striped hover :items="items" :fields="fields" responsive>
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '5%' : '' }"
                />
              </template>
              <template #cell(doctor)="data">
                <ul>
                  <li>
                    <span class="d-block font-weight-bolder">Operator :</span>
                    <ul
                      v-for="operator in data.item.operator_display"
                      :key="operator.id"
                    >
                      <li class="ml-5">{{ operator.name }}</li>
                    </ul>
                  </li>
                  <li>
                    <span class="d-block font-weight-bolder">Anestesi :</span>
                    <ul
                      v-for="anesthetist in data.item.anesthetist_display"
                      :key="anesthetist.id"
                    >
                      <li class="ml-5">{{ anesthetist.name }}</li>
                    </ul>
                  </li>
                  <li>
                    <span class="d-block font-weight-bolder"
                      >Penanggung Jawab :</span
                    >
                    <ul
                      v-for="responsible_doctor in data.item
                        .responsible_doctor_display"
                      :key="responsible_doctor.id"
                    >
                      <li class="ml-5">{{ responsible_doctor.name }}</li>
                    </ul>
                  </li>
                </ul>
                <!-- <span v-if="data.item.operator_display.length < 1">-</span> -->
              </template>

              <!-- <template #cell(operator)="data">
                <ul
                  v-for="operator in data.item.operator_display"
                  :key="operator.id"
                >
                  <li class="ml-5">{{ operator.name }}</li>
                </ul>
                
              </template> -->

              <template #cell(action_type)="data">
                <!-- {{ displayActionType(data.item.action_type_display) }} -->
                <ul
                  v-for="action_type in data.item.action_type_display"
                  :key="action_type.id"
                >
                  <li class="ml-5">{{ action_type.name }}</li>
                </ul>
                <!-- <span v-if="data.item.operator_display.length < 1">-</span> -->
              </template>

              <template #cell(medicines_order)="data">
                <!-- {{ displayAnesthetist(data.item.anesthetist_display) }} -->
                <ul
                  v-for="medicines in data.item.medicines_order_data"
                  :key="medicines.id"
                >
                  <li class="ml-5">
                    <span class="d-block">{{
                      medicines.name + ":" + medicines.quantity
                    }}</span>
                    <span class="d-block">({{ medicines.total_price }})</span>
                    <!-- {{ medicines.name + ":" + medicines.quantity + `(${medicines.total_price})` }} -->
                  </li>
                </ul>
                <span
                  style="cursor: pointer"
                  class="text-primary"
                  @click="paginationMore(data.item.id)"
                  v-if="
                    idData != data.item.id &&
                    data.item.medicines_order.length > 2
                  "
                  >{{
                    parseInt(data.item.medicines_order.length) - 2
                  }}
                  lainnya</span
                >
                <span
                  style="cursor: pointer"
                  class="text-primary"
                  @click="pagination"
                  v-if="
                    idData == data.item.id &&
                    data.item.medicines_order.length > 2
                  "
                  >Lebih Sedikit</span
                >
                <!-- <span v-if="data.item.anesthetist_display.length < 1">-</span> -->
              </template>
              <template #cell(medicines_used)="data">
                <!-- {{ displayAnesthetist(data.item.anesthetist_display) }} -->
                <ul
                  v-for="medicines in data.item.medicines_used_data"
                  :key="medicines.id"
                >
                  <li class="ml-5">
                    <span class="d-block">{{
                      medicines.name + ":" + medicines.quantity
                    }}</span>
                    <span class="d-block">({{ medicines.total_price }})</span>
                    <!-- {{ medicines.name + ":" + medicines.quantity + `(${medicines.total_price})` }} -->
                  </li>
                </ul>
                <span
                  style="cursor: pointer"
                  class="text-primary"
                  @click="paginationMoreUsed(data.item.id)"
                  v-if="
                    idDataUsed != data.item.id &&
                    data.item.medicines_used.length > 2
                  "
                  >{{
                    parseInt(data.item.medicines_used.length) - 2
                  }}
                  lainnya</span
                >
                <span
                  style="cursor: pointer"
                  class="text-primary"
                  @click="pagination"
                  v-if="
                    idDataUsed == data.item.id &&
                    data.item.medicines_used.length > 2
                  "
                  >Lebih Sedikit</span
                >
                <!-- <span v-if="data.item.anesthetist_display.length < 1">-</span> -->
              </template>

              <template #cell(inventory)="data">
                
                <ul
                  v-for="inventory_display in data.item.inventory_display_data"
                  :key="inventory_display.id"
                >
                  <li class="ml-5">
                    <span class="d-block">{{
                      inventory_display.name + ":" + inventory_display.quantity
                    }}</span>
                  </li>
                </ul>
                <span
                  style="cursor: pointer"
                  class="text-primary"
                  @click="paginationMoreInventory(data.item.id)"
                  v-if="
                    idDataUsed != data.item.id &&
                    data.item.inventory_display.length > 2
                  "
                  >{{
                    parseInt(data.item.inventory_display.length) - 2
                  }}
                  lainnya</span
                >
                <span
                  style="cursor: pointer"
                  class="text-primary"
                  @click="pagination"
                  v-if="
                    idDataUsed == data.item.id &&
                    data.item.inventory_display.length > 2
                  "
                  >Lebih Sedikit</span
                >
                <!-- <span v-if="data.item.anesthetist_display.length < 1">-</span> -->
              </template>

              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info d-block mx-auto"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({
                      path: '/medical-actions/detail/' + data.item.id,
                    })
                  "
                >
                  <i class="fas fa-eye px-0"></i>
                </b-button>
                <!-- <b-button
                  size="sm"
                  class="mr-1 btn-success d-block mt-1 mx-auto"
                  v-b-tooltip.hover
                  title="Edit"
                  v-if="manipulateBtn == true"
                  @click="
                    $router.push({
                      path: '/medical-actions/edit/' + data.item.id,
                    })
                  "
                >
                  <i class="fas fa-edit px-0"></i>
                </b-button> -->
                <b-button
                  size="sm"
                  class="btn-danger d-block mt-1 mx-auto"
                  v-b-tooltip.hover
                  title="Hapus"
                  v-if="manipulateBtn == true"
                  @click="deleteData(data.item.id)"
                >
                  <i class="fas fa-trash px-0"></i>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>

    <!-- modal export -->
    <b-modal
      id="modal-export-action"
      size="xl"
      :title="'Expor Daftar Tindakan'"
      style="max-width: 30px; overflow: scroll"
      hide-footer
    >
      <div class="d-flex justify-content-start align-items-center mt-5">
        <button
          class="btn btn-warning mr-1"
          v-b-tooltip.hover
          title="Download Excel"
          @click="btnExcelOnClick"
        >
          <i class="fas fa-file-excel px-0"></i>
        </button>
      </div>
      <b-table
        striped
        hover
        :items="itemsDisplay"
        :fields="fieldsExport"
        responsive
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '5%' : '' }"
          />
        </template>
        <template #cell(doctor)="data">
          <ul>
            <li>
              <span class="d-block font-weight-bolder">Operator :</span>
              <ul
                v-for="operator in data.item.operator_display"
                :key="operator.id"
              >
                <li class="ml-5">{{ operator.name }}</li>
              </ul>
            </li>
            <li>
              <span class="d-block font-weight-bolder">Anestesi :</span>
              <ul
                v-for="anesthetist in data.item.anesthetist_display"
                :key="anesthetist.id"
              >
                <li class="ml-5">{{ anesthetist.name }}</li>
              </ul>
            </li>
            <li>
              <span class="d-block font-weight-bolder">Penanggung Jawab :</span>
              <ul
                v-for="responsible_doctor in data.item
                  .responsible_doctor_display"
                :key="responsible_doctor.id"
              >
                <li class="ml-5">{{ responsible_doctor.name }}</li>
              </ul>
            </li>
          </ul>
        </template>

        <template #cell(assistant)="data">
          <ul>
            <li>
              <span class="d-block font-weight-bolder">Asisten :</span>
              <ul
                v-for="assistant in data.item.assistant_display"
                :key="assistant.id"
              >
                <li class="ml-5">
                  {{ assistant.name }}
                </li>
              </ul>
            </li>
            <li>
              <span class="d-block font-weight-bolder">Omploop :</span>
              <ul
                v-for="omploop in data.item.omploop_display"
                :key="omploop.id"
              >
                <li class="ml-5">
                  {{ omploop.name }}
                </li>
              </ul>
            </li>
            <li>
              <span class="d-block font-weight-bolder">Tindakan Perawat :</span>
              <ul
                v-for="nurse_action in data.item.nurse_action_display"
                :key="nurse_action.id"
              >
                <li class="ml-5">
                  {{ nurse_action.name }}
                </li>
              </ul>
            </li>
          </ul>
        </template>

        <template #cell(action_type)="data">
          <ul
            v-for="action_type in data.item.action_type_display"
            :key="action_type.id"
          >
            <li class="ml-5">{{ action_type.name }}</li>
          </ul>
        </template>

        <template #cell(medicines_used)="data">
          <ul
            v-for="medicines in data.item.medicines_used_data"
            :key="medicines.id"
          >
            <li class="ml-5">
              <span class="d-block">{{
                medicines.name + ":" + medicines.quantity
              }}</span>
              <span class="d-block">({{ medicines.total_price }})</span>
            </li>
          </ul>
          <!-- {{data.item.medicines_length}} -->
          <span
            style="cursor: pointer"
            class="text-primary"
            @click="paginationMorePopupUsed(data.item.id)"
            v-if="
              idDataPopUpUsed != data.item.id &&
              data.item.medicines_used.length > 2
            "
            >{{ parseInt(data.item.medicines_used.length) - 2 }} lainnya</span
          >
          <span
            style="cursor: pointer"
            class="text-primary"
            @click="exportShow"
            v-if="
              idDataPopUpUsed == data.item.id &&
              data.item.medicines_used.length > 2
            "
            >Lebih Sedikit</span
          >
        </template>

        <template #cell(medicines_order)="data">
          <ul
            v-for="medicines in data.item.medicines_order_data"
            :key="medicines.id"
          >
            <li class="ml-5">
              <span class="d-block">{{
                medicines.name + ":" + medicines.quantity
              }}</span>
              <span class="d-block">({{ medicines.total_price }})</span>
            </li>
          </ul>
          <!-- {{data.item.medicines_length}} -->
          <span
            style="cursor: pointer"
            class="text-primary"
            @click="paginationMorePopup(data.item.id)"
            v-if="
              idDataPopUp != data.item.id &&
              data.item.medicines_order.length > 2
            "
            >{{ parseInt(data.item.medicines_order.length) - 2 }} lainnya</span
          >
          <span
            style="cursor: pointer"
            class="text-primary"
            @click="exportShow"
            v-if="
              idDataPopUp == data.item.id &&
              data.item.medicines_order.length > 2
            "
            >Lebih Sedikit</span
          >
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service.js";
import validation from "@/core/modules/ValidationModule.js";
import XLSX from "xlsx";
import moment from "moment";

export default {
  components: {
    Card,
    XLSX,
  },

  data() {
    return {
      // Filter
      filter: {
        patient_name: "",
        action_type: "",
        start_date: "",
        end_date: "",
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      recordData: 0,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date_display",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
       
        {
          key: "action_type",
          label: "Diagnosa Tindakan",
          sortable: true,
        },
        {
          key: "doctor",
          label: "Dokter",
          sortable: true,
        },
       
        {
          key: "medicines_used",
          label: "Obat/Alkes",
          
        },
        {
          key: "medicines_order",
          label: "Obat/Alkes Dibawa Pulang",
          
        },
        {
          key: "inventory",
          label: "Inventori",
          
        },
        { key: "actions", label: "Aksi", thClass: "text-center" },
      ],
      fieldsExport: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date_display",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        // {
        //   key: "patient_age",
        //   label: "Umur",
        //   sortable: true,
        // },
        {
          key: "action_type",
          label: "Diagnosa Tindakan",
          sortable: true,
        },
        {
          key: "doctor",
          label: "Dokter",
          sortable: true,
        },
        {
          key: "assistant",
          label: "Asisten",
          sortable: true,
        },

        {
          key: "anesteshia",
          label: "Anastesi",
          sortable: true,
        },
        // {
        //   key: "anesthetist",
        //   label: "Dokter Anastesi",
        //   sortable: true,
        // },
        {
          key: "medicines_used",
          label: "Obat/Alkes",
          sortable: true,
        },
        {
          key: "medicines_order",
          label: "Obat/Alkes Dibawa Pulang",
          sortable: true,
        },
        // { key: "actions", label: "Aksi", thClass: "text-center"},
      ],
      items: [],
      itemsExcel: [],
      itemsDisplay: [],
      action_types: [],
      electro_laser_display: "",
      manipulateBtn: false,
      idData: null,
      idDataUsed: null,
      idDataPopUp: null,
      idDataPopUpUsed: null,
    };
  },

  methods: {
    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.itemsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Daftar Tindakan Medis.xlsx");

      this.$bvModal.hide("modal-export-action");
    },

    resetFilter() {
      this.filter = validation.clearForm(this.filter);
      this.filter.start_date = moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.filter.end_date = moment()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.pagination();
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      this.idData = null;
      this.idDataUsed = null;
      let filterParams = `&patient_name=${this.filter.patient_name}&action_type=${this.filter.action_type}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      // let filterParamsReport = `?patient_name=${this.filter.patient_name}&action_type=${this.filter.action_type}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        `medical-actions`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      // let responseExcel = await module.get(
      //   `medical-action-excel`,
      //   `${filterParamsReport}`
      // );
      // let responseDisplay = await module.get(
      //   `medical-action-no-pagination`,
      //   `${filterParamsReport}`
      // );

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
        let date = new Date(response.data[a].date);
        let convert = date.toLocaleString().split(",")[0];
        response.data[a].date = convert;
      }

      this.items = response.data;
      

      // medicines used
      let g,
        i,
        arr = [];
      for (g = 0; g < this.items.length; g++) {
        arr = [];
        for (i = 0; i < this.items[g].medicines_used.length; i++) {
          if (i < 2) {
            arr.push(this.items[g].medicines_used[i]);
          }
        }
        this.items[g].medicines_used_data = arr;
      }

      // medicines order
      let j,
        k,
        arrData = [];
      for (j = 0; j < this.items.length; j++) {
        arrData = [];
        for (k = 0; k < this.items[j].medicines_order.length; k++) {
          if (k < 2) {
            arrData.push(this.items[j].medicines_order[k]);
          }
        }
        this.items[j].medicines_order_data = arrData;
      }

      console.log(this.items.length,"length");
      
      let l,
        m,
        arrDataInv = [];
      for (l = 0; l < this.items.length; l++) {
        arrDataInv = [];
        
        for (m = 0; m < this.items[l].inventory_display.length; m++) {
          if (m < 2) {
            arrDataInv.push(this.items[l].inventory_display[m]);
          }
        }
        this.items[l].inventory_display_data = arrDataInv;
      }

      // let arrDisplay=[]

      // this.itemsDisplay.forEach((val,index) =>{
      //   arrDisplay = []

      //   if(val.medicines_display != null){
      //     val.medicines_display.forEach((med,i) =>{
      //       if(i<2){
      //         arrDisplay.push(med)
      //       }
      //     })
      //     this.itemsDisplay[index].medicines_paging = arrDisplay

      //     this.itemsDisplay[index].medicines_length = this.itemsDisplay[index].medicines_display.length
      //   }else{
      //      this.itemsDisplay[index].medicines_paging = []
      //      this.itemsDisplay[index].medicines_length = 0

      //   }
      // })

      console.log(this.itemsDisplay, "items");
    },

    async paginationMore(id) {
      this.idData = id;

      let filterParams = `&patient_name=${this.filter.patient_name}&action_type=${this.filter.action_type}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;

      let response = await module.paginate(
        `medical-actions`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
        let date = new Date(response.data[a].date);
        let convert = date.toLocaleString().split(",")[0];
        response.data[a].date = convert;
      }

      this.items = response.data;

      let j,
        k,
        arrData = [];
      for (j = 0; j < this.items.length; j++) {
        arrData = [];
        for (k = 0; k < this.items[j].medicines_used.length; k++) {
          if (k < 2) {
            arrData.push(this.items[j].medicines_used[k]);
          }
        }
        this.items[j].medicines_used_data = arrData;
      }

      let g,
        i,
        arr = [];
      for (g = 0; g < this.items.length; g++) {
        if (this.items[g].id != id) {
          arr = [];
          for (i = 0; i < this.items[g].medicines_order.length; i++) {
            if (i < 2) {
              arr.push(this.items[g].medicines_order[i]);
            }
          }
          this.items[g].medicines_order_data = arr;
        } else {
          this.items[g].medicines_order_data = this.items[g].medicines_order;
        }
      }
    },
    async paginationMoreUsed(id) {
      this.idDataUsed = id;

      let filterParams = `&patient_name=${this.filter.patient_name}&action_type=${this.filter.action_type}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;

      let response = await module.paginate(
        `medical-actions`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
        let date = new Date(response.data[a].date);
        let convert = date.toLocaleString().split(",")[0];
        response.data[a].date = convert;
      }

      this.items = response.data;

      let i,
        g,
        arr = [];
      for (i = 0; i < this.items.length; i++) {
        arr = [];
        for (g = 0; g < this.items[i].medicines_order.length; g++) {
          if (g < 2) {
            arr.push(this.items[i].medicines_order[g]);
          }
        }
        this.items[i].medicines_order_data = arr;
      }

      let j,
        k,
        arrData = [];
      for (j = 0; j < this.items.length; j++) {
        if (this.items[j].id != id) {
          arrData = [];
          for (k = 0; k < this.items[j].medicines_used.length; k++) {
            if (k < 2) {
              arrData.push(this.items[j].medicines_used[k]);
            }
          }
          this.items[j].medicines_used_data = arrData;
        } else {
          this.items[j].medicines_used_data = this.items[j].medicines_used;
        }
      }
    },
    
    async paginationMoreInventory(id) {
      this.idDataUsed = id;

      let filterParams = `&patient_name=${this.filter.patient_name}&action_type=${this.filter.action_type}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;

      let response = await module.paginate(
        `medical-actions`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
        let date = new Date(response.data[a].date);
        let convert = date.toLocaleString().split(",")[0];
        response.data[a].date = convert;
      }

      this.items = response.data;

      let l,
        m,
        arrDataInv = [];
      for (l = 0; l < this.items.length; l++) {
        if (this.items[l].id != id) {
          arrDataInv = [];
          for (m = 0; m < this.items[l].inventory_display.length; m++) {
            if (m < 2) {
              arrDataInv.push(this.items[l].inventory_display[m]);
            }
          }
          this.items[l].inventory_display_data = arrDataInv;
        } else {
          this.items[l].inventory_display_data = this.items[l].inventory_display;
        }
      }

       let i,
        g,
        arr = [];
      for (i = 0; i < this.items.length; i++) {
        arr = [];
        for (g = 0; g < this.items[i].medicines_order.length; g++) {
          if (g < 2) {
            arr.push(this.items[i].medicines_order[g]);
          }
        }
        this.items[i].medicines_order_data = arr;
      }

      let j,
        k,
        arrData = [];
      for (j = 0; j < this.items.length; j++) {
        arrData = [];
        for (k = 0; k < this.items[j].medicines_used.length; k++) {
          if (k < 2) {
            arrData.push(this.items[j].medicines_used[k]);
          }
        }
        this.items[j].medicines_used_data = arrData;
      }
    },

    async paginationMorePopup(id) {
      this.idDataPopUp = id;

      let filterParamsReport = `?patient_name=${this.filter.patient_name}&action_type=${this.filter.action_type}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;

      let responseDisplay = await module.get(
        `medical-action-no-pagination`,
        `${filterParamsReport}`
      );

      this.itemsDisplay = responseDisplay;

      let h,
        j,
        arrDisplay = [],
        arrOrder = [];
      // for(h=0;h<this.itemsDisplay.length;h++){
      //   if(this.itemsDisplay[h].id != id){
      //     arrDisplay = []
      //       for(j=0;j<this.itemsDisplay[h].medicines_display.length;j++){
      //         if(j<2){
      //         arrDisplay.push(this.itemsDisplay[h].medicines_display[j])
      //         }

      //       }
      //     this.itemsDisplay[h].medicines_paging = arrDisplay
      //     this.itemsDisplay[h].medicines_length = this.itemsDisplay[h].medicines_display.length

      //   }else{
      //     this.itemsDisplay[h].medicines_paging = this.itemsDisplay[h].medicines_display
      //   }

      // }

      this.itemsDisplay.forEach((val, index) => {
        arrOrder = [];

        if (val.medicines_used != null) {
          val.medicines_used.forEach((med, i) => {
            if (i < 2) {
              arrOrder.push(med);
            }
          });
          this.itemsDisplay[index].medicines_used_data = arrOrder;

          this.itemsDisplay[index].medicines_length =
            this.itemsDisplay[index].medicines_used.length;
        } else {
          this.itemsDisplay[index].medicines_used_data = [];
          this.itemsDisplay[index].medicines_length = 0;
        }
      });

      this.itemsDisplay.forEach((val, index) => {
        console.log("======================= cek jalan gk ==================");
        if (val.id != id) {
          arrDisplay = [];

          if (val.medicines_order != null) {
            val.medicines_order.forEach((med, i) => {
              if (i < 2) {
                arrDisplay.push(med);
              }
            });
            this.itemsDisplay[index].medicines_order_data = arrDisplay;
            this.itemsDisplay[index].medicines_length =
              this.itemsDisplay[index].medicines_order.length;
          } else {
            this.itemsDisplay[index].medicines_order_data = [];
            this.itemsDisplay[index].medicines_length = 0;
          }
        } else {
          this.itemsDisplay[index].medicines_order_data =
            this.itemsDisplay[index].medicines_order;
        }
      });
    },

    async paginationMorePopupUsed(id) {
      this.idDataPopUpUsed = id;

      let filterParamsReport = `?patient_name=${this.filter.patient_name}&action_type=${this.filter.action_type}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;

      let responseDisplay = await module.get(
        `medical-action-no-pagination`,
        `${filterParamsReport}`
      );

      this.itemsDisplay = responseDisplay;

      let h,
        j,
        arrDisplay = [],
        arrOrder = [];
      // for(h=0;h<this.itemsDisplay.length;h++){
      //   if(this.itemsDisplay[h].id != id){
      //     arrDisplay = []
      //       for(j=0;j<this.itemsDisplay[h].medicines_display.length;j++){
      //         if(j<2){
      //         arrDisplay.push(this.itemsDisplay[h].medicines_display[j])
      //         }

      //       }
      //     this.itemsDisplay[h].medicines_paging = arrDisplay
      //     this.itemsDisplay[h].medicines_length = this.itemsDisplay[h].medicines_display.length

      //   }else{
      //     this.itemsDisplay[h].medicines_paging = this.itemsDisplay[h].medicines_display
      //   }

      // }

      this.itemsDisplay.forEach((val, index) => {
        arrOrder = [];

        if (val.medicines_order != null) {
          val.medicines_order.forEach((med, i) => {
            if (i < 2) {
              arrOrder.push(med);
            }
          });
          this.itemsDisplay[index].medicines_order_data = arrOrder;

          this.itemsDisplay[index].medicines_length =
            this.itemsDisplay[index].medicines_order.length;
        } else {
          this.itemsDisplay[index].medicines_order_data = [];
          this.itemsDisplay[index].medicines_length = 0;
        }
      });

      this.itemsDisplay.forEach((val, index) => {
        if (val.id != id) {
          arrDisplay = [];

          if (val.medicines_used != null) {
            val.medicines_used.forEach((med, i) => {
              if (i < 2) {
                arrDisplay.push(med);
              }
            });
            this.itemsDisplay[index].medicines_used_data = arrDisplay;
            this.itemsDisplay[index].medicines_length =
              this.itemsDisplay[index].medicines_used.length;
          } else {
            this.itemsDisplay[index].medicines_used_data = [];
            this.itemsDisplay[index].medicines_length = 0;
          }
        } else {
          this.itemsDisplay[index].medicines_used_data =
            this.itemsDisplay[index].medicines_used;
        }
      });
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("medical-actions/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    },

    displayOperator(operator) {
      let str = "";
      operator.forEach(function (value, index) {
        str += value.name;
        if (index != operator.length - 1) {
          if (operator.length > 1) str += ", ";
        }
      });
      return str;
    },

    displayAnesthetist(anesthetist) {
      let str = "";
      anesthetist.forEach(function (value, index) {
        str += value.name;
        if (index != anesthetist.length - 1) {
          if (anesthetist.length > 1) str += ", ";
        }
      });
      return str;
    },

    displayActionType(action_type) {
      let str = "";
      action_type.forEach(function (value, index) {
        str += value.name;
        if (index != action_type.length - 1) {
          if (action_type.length > 1) str += ", ";
        }
      });
      return str;
    },

    async getActionTypeOption() {
      let response = await module.setTreeSelectMedicalAction(
        "payment-categories"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.action_types = response.data;
        this.action_types.unshift({
          label: "Pilih Jenis Tindakan",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByName() {
      this.filter.start_date = "";
      this.filter.end_date = "";

      if (this.filter.patient_name == "") {
        this.btnActiveOnClick(this.lastPeriodType);
      }

      this.pagination();
    },
    async filterByActionType(evt) {
      if (!evt) {
        this.filter.action_type = await "";
        this.pagination();
      } else {
        this.filter.action_type = await evt;
        this.pagination();
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1202") {
          this.manipulateBtn = true;
        }
      }
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "days";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "month";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "year";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    async btnExportOnClick() {
      this.$bvModal.show("modal-export-action");

      // get data
      let filterParamsReport = `?patient_name=${this.filter.patient_name}&action_type=${this.filter.action_type}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;

      let responseExcel = await module.get(
        `medical-action-excel`,
        `${filterParamsReport}`
      );
      let responseDisplay = await module.get(
        `medical-action-no-pagination`,
        `${filterParamsReport}`
      );

      this.itemsDisplay = responseDisplay;
      this.itemsExcel = responseExcel;

      let medicineString = "",
        medicineOrderString = "",
        omploopString = "",
        operatorString = "",
        assistantString = "",
        anesthetistString = "",
        responsibleString = "",
        nurseActionString = "",
        actionString = "";

      this.itemsExcel.forEach((val, index) => {
        // console.log(val.medicines_display,"value");
        medicineString = "";
        medicineOrderString = "";
        omploopString = "";
        operatorString = "";
        assistantString = "";
        anesthetistString = "";
        responsibleString = "";
        nurseActionString = "";
        actionString = "";

        // medicine
        if (val.medicines_used != null) {
          val.medicines_used.forEach((medic, m) => {
            // console.log(medic,"medic");
            if (medicineString === "") {
              medicineString =
                medic.name +
                " (" +
                medic.quantity +
                " X " +
                medic.sales_price +
                ")";
            } else {
              medicineString =
                medicineString +
                ", " +
                medic.name +
                " (" +
                medic.quantity +
                " X " +
                medic.sales_price +
                ")";
            }
          });
        } else {
          medicineString = "";
        }

        // medicine
        if (val.medicines_order != null) {
          val.medicines_order.forEach((medic, m) => {
            // console.log(medic,"medic");
            if (medicineOrderString === "") {
              medicineOrderString =
                medic.name +
                " (" +
                medic.quantity +
                " X " +
                medic.sales_price +
                ")";
            } else {
              medicineOrderString =
                medicineOrderString +
                ", " +
                medic.name +
                " (" +
                medic.quantity +
                " X " +
                medic.sales_price +
                ")";
            }
          });
        } else {
          medicineOrderString = "";
        }

        // omploop
        if (val.omploop_display != null) {
          val.omploop_display.forEach((omploop) => {
            // console.log(medic,"medic");
            if (omploopString === "") {
              omploopString = omploop.name;
            } else {
              omploopString = omploopString + ", " + omploop.name;
            }
          });
        } else {
          omploopString = "";
        }

        // operator
        if (val.operator_display != null) {
          val.operator_display.forEach((operator) => {
            // console.log(medic,"medic");
            if (operatorString === "") {
              operatorString = operator.name;
            } else {
              operatorString = operatorString + ", " + operator.name;
            }
          });
        } else {
          operatorString = "";
        }

        // assistant
        if (val.assistant_display != null) {
          val.assistant_display.forEach((assistant) => {
            // console.log(medic,"medic");
            if (assistantString === "") {
              assistantString = assistant.name;
            } else {
              assistantString = assistantString + ", " + assistant.name;
            }
          });
        } else {
          assistantString = "";
        }

        // anesthetist
        if (val.anesthetist_display != null) {
          val.anesthetist_display.forEach((anesthetist) => {
            // console.log(medic,"medic");
            if (anesthetistString === "") {
              anesthetistString = anesthetist.name;
            } else {
              anesthetistString = anesthetistString + ", " + anesthetist.name;
            }
          });
        } else {
          anesthetistString = "";
        }

        // responsible
        if (val.responsible_doctor_display != null) {
          val.responsible_doctor_display.forEach((responsible) => {
            // console.log(medic,"medic");
            if (responsibleString === "") {
              responsibleString = responsible.name;
            } else {
              responsibleString = responsibleString + ", " + responsible.name;
            }
          });
        } else {
          responsibleString = "";
        }

        // nurse action
        if (val.nurse_action_display != null) {
          val.nurse_action_display.forEach((nurseAction) => {
            // console.log(medic,"medic");
            if (nurseActionString === "") {
              nurseActionString = nurseAction.name;
            } else {
              nurseActionString = nurseActionString + ", " + nurseAction.name;
            }
          });
        } else {
          nurseActionString = "";
        }
        // nurse action
        if (val.nurse_action_display != null) {
          val.nurse_action_display.forEach((nurseAction) => {
            // console.log(medic,"medic");
            if (nurseActionString === "") {
              nurseActionString = nurseAction.name;
            } else {
              nurseActionString = nurseActionString + ", " + nurseAction.name;
            }
          });
        } else {
          nurseActionString = "";
        }

        // nurse action
        if (val.action_type_display != null) {
          val.action_type_display.forEach((action) => {
            // console.log(medic,"medic");
            if (actionString === "") {
              actionString = action.name;
            } else {
              actionString = actionString + ", " + action.name;
            }
          });
        } else {
          actionString = "";
        }
        // delete this.itemsExcel[index].medicines_display

        this.itemsExcel[index].Obat = medicineString;
        delete this.itemsExcel[index].medicines_used;

        this.itemsExcel[index].Obat_Dibawa_Pulang = medicineOrderString;
        delete this.itemsExcel[index].medicines_order;

        this.itemsExcel[index].Omploop = omploopString;
        delete this.itemsExcel[index].omploop_display;

        this.itemsExcel[index].Operator = operatorString;
        delete this.itemsExcel[index].operator_display;

        this.itemsExcel[index].Asisten = assistantString;
        delete this.itemsExcel[index].assistant_display;

        this.itemsExcel[index].Anestesi = anesthetistString;
        delete this.itemsExcel[index].anesthetist_display;

        this.itemsExcel[index].Penanggung_jawab = responsibleString;
        delete this.itemsExcel[index].responsible_doctor_display;

        this.itemsExcel[index].Tindakan_perawat = nurseActionString;
        delete this.itemsExcel[index].nurse_action_display;

        this.itemsExcel[index].Tindakan = actionString;
        delete this.itemsExcel[index].action_type_display;
      });

      console.log(this.itemsExcel, "excel");

      let g,
        i,
        arr = [];
      for (g = 0; g < this.items.length; g++) {
        arr = [];
        for (i = 0; i < this.items[g].medicines_display.length; i++) {
          if (i < 2) {
            arr.push(this.items[g].medicines_display[i]);
          }
        }
        this.items[g].medicines_paging = arr;
      }
      let arrDisplay = [];

      this.itemsDisplay.forEach((val, index) => {
        arrDisplay = [];

        if (val.medicines_order != null) {
          val.medicines_order.forEach((med, i) => {
            if (i < 2) {
              arrDisplay.push(med);
            }
          });
          this.itemsDisplay[index].medicines_order_data = arrDisplay;

          this.itemsDisplay[index].medicines_length =
            this.itemsDisplay[index].medicines_order.length;
        } else {
          this.itemsDisplay[index].medicines_order_data = [];
          this.itemsDisplay[index].medicines_length = 0;
        }
      });

      this.itemsDisplay.forEach((val, index) => {
        arrDisplay = [];

        if (val.medicines_used != null) {
          val.medicines_used.forEach((med, i) => {
            if (i < 2) {
              arrDisplay.push(med);
            }
          });
          this.itemsDisplay[index].medicines_used_data = arrDisplay;

          this.itemsDisplay[index].medicines_length =
            this.itemsDisplay[index].medicines_used.length;
        } else {
          this.itemsDisplay[index].medicines_used_data = [];
          this.itemsDisplay[index].medicines_length = 0;
        }
      });
    },

    async exportShow() {
      this.idDataPopUp = null;
      this.idDataPopUpUsed = null;

      // get data
      let filterParamsReport = `?patient_name=${this.filter.patient_name}&action_type=${this.filter.action_type}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;

      let responseExcel = await module.get(
        `medical-action-excel`,
        `${filterParamsReport}`
      );
      let responseDisplay = await module.get(
        `medical-action-no-pagination`,
        `${filterParamsReport}`
      );

      this.itemsDisplay = responseDisplay;
      this.itemsExcel = responseExcel;

      let g,
        i,
        arr = [];
      for (g = 0; g < this.items.length; g++) {
        arr = [];
        for (i = 0; i < this.items[g].medicines_display.length; i++) {
          if (i < 2) {
            arr.push(this.items[g].medicines_display[i]);
          }
        }
        this.items[g].medicines_paging = arr;
      }
      let arrDisplay = [];

      this.itemsDisplay.forEach((val, index) => {
        arrDisplay = [];

        if (val.medicines_order != null) {
          val.medicines_order.forEach((med, i) => {
            if (i < 2) {
              arrDisplay.push(med);
            }
          });
          this.itemsDisplay[index].medicines_order_data = arrDisplay;

          this.itemsDisplay[index].medicines_length =
            this.itemsDisplay[index].medicines_order.length;
        } else {
          this.itemsDisplay[index].medicines_order_data = [];
          this.itemsDisplay[index].medicines_length = 0;
        }
      });

      this.itemsDisplay.forEach((val, index) => {
        arrDisplay = [];

        if (val.medicines_used != null) {
          val.medicines_used.forEach((med, i) => {
            if (i < 2) {
              arrDisplay.push(med);
            }
          });
          this.itemsDisplay[index].medicines_used_data = arrDisplay;

          this.itemsDisplay[index].medicines_length =
            this.itemsDisplay[index].medicines_used.length;
        } else {
          this.itemsDisplay[index].medicines_used_data = [];
          this.itemsDisplay[index].medicines_length = 0;
        }
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan Tindakan Medis", route: "/medical-actions" },
      { title: "Daftar Tindakan Medis" },
    ]);

    this.pagination();
    this.getActionTypeOption();
    this.setActiveMenu();
  },
};
</script>

<style>
.nowrap {
  white-space: normal;
}
.th-action {
  white-space: nowrap !important;
}

.td-center {
  text-align: center !important;
}

@media (max-width: 767.98px) {
  .nowrap {
    white-space: nowrap;
  }
}
</style>